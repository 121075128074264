<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="avatar"
          :text="avatarText(form.name)"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ form.name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.file.click()"
        >
          <input
            ref="file"
            type="file"
            class="d-none"
            @change="onFileChange"
          >
          <span class="d-none d-sm-inline">Ubah Avatar</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Nama"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="form.name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="form.email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <!-- <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Gelar Depan"
            label-for="gelar_depan"
          >
            <b-form-input
              id="gelar_depan"
              v-model="form.gelar_depan"
            />
          </b-form-group>
        </b-col> -->

        <!-- <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Gelar Belakang"
            label-for="gelar_belakang"
          >
            <b-form-input
              id="gelar_belakang"
              v-model="form.gelar_belakang"
            />
          </b-form-group>
        </b-col> -->

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Tempat Lahir"
            label-for="tempat_lahir"
          >
            <b-form-input
              id="tempat_lahir"
              v-model="form.tempat_lahir"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Tanggal Lahir"
            label-for="tanggal_lahir"
          >
            <flat-pickr
              v-model="form.tanggal_lahir"
              class="form-control"
              :config="{
                altInput: true,
                altFormat: 'j F Y',
                dateFormat: 'Y-m-d',
                maxDate: 'today'
              }"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Jabatan"
            label-for="jabatan"
          >
            <b-form-input
              id="jabatan"
              v-model="form.jabatan"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Instansi"
            label-for="instansi"
          >
            <b-form-input
              id="instansi"
              v-model="form.instansi"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Golongan"
            label-for="golongan"
          >
            <b-form-input
              id="golongan"
              v-model="form.golongan"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="NIP"
            label-for="nip"
          >
            <b-form-input
              id="nip"
              v-model="form.nip"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="NIK"
            label-for="nik"
          >
            <b-form-input
              id="nik"
              v-model="form.nik"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="No HP"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="form.phone"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="Alamat"
            label-for="address"
          >
            <b-form-textarea
              id="address"
              v-model="form.address"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="submit()"
    >
      Simpan
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { reqUpdateProfile } from '@/api/auth'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        address: '',
        avatar: '',
        gelar_depan: '',
        gelar_belakang: '',
        tempat_lahir: '',
        tanggal_lahir: '',
        golongan: '',
        jabatan: '',
        instansi: '',
        nip: '',
        nik: '',
      },
      avatar: '',
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  mounted() {
    this.form.name = this.userData.name
    this.form.email = this.userData.email
    this.form.phone = this.userData.profile.phone ?? ''
    this.form.address = this.userData.profile.address ?? ''
    this.form.gelar_depan = this.userData.profile.gelar_depan ?? ''
    this.form.gelar_belakang = this.userData.profile.gelar_belakang ?? ''
    this.form.tempat_lahir = this.userData.profile.tempat_lahir ?? ''
    this.form.tanggal_lahir = this.userData.profile.tanggal_lahir ?? ''
    this.form.golongan = this.userData.profile.golongan ?? ''
    this.form.jabatan = this.userData.profile.jabatan ?? ''
    this.form.instansi = this.userData.profile.instansi ?? ''
    this.form.nip = this.userData.profile.nip ?? ''
    this.form.nik = this.userData.profile.nik ?? ''
    this.avatar = this.userData.avatar
  },
  methods: {
    submit() {
      const data = new FormData()
      for (const i in this.form) {
        data.append(i, this.form[i])
      }
      reqUpdateProfile(data)
        .then(response => {
          const userData = response.data.data
          userData.ability = [{
            action: 'manage',
            subject: 'all',
          }]
          localStorage.setItem('userData', JSON.stringify(userData))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Berhasil',
              text: 'Berhasil menyimpan data',
              icon: 'CheckIcon',
              variant: 'danger',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              text: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    onFileChange(e) {
      const file = e.target.files[0]
      this.form.avatar = file
      this.avatar = URL.createObjectURL(file)
    },
    avatarText,
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
