<template>
  <div>
    <b-form>
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="Password lama"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="form.old_password"
              type="password"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Password Baru"
            label-for="address"
          >
            <b-form-input
              id="address"
              v-model="form.password"
              type="password"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Konfirmasi Password"
            label-for="address"
          >
            <b-form-input
              id="address"
              v-model="form.password_confirmation"
              type="password"
            />
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="submit()"
    >
      Simpan
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { reqUpdatePassword } from '@/api/auth'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      form: {
        old_password: '',
        password: '',
        password_confirmation: '',
      },
    }
  },
  methods: {
    submit() {
      reqUpdatePassword(this.form)
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Berhasil',
              text: 'Berhasil mengubah password',
              icon: 'CheckIcon',
              variant: 'danger',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              text: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
